<template>
  <div class="combi-product-bg-graphics">
    <img :src="image" alt="Náhled produktu" class="pack">
    <ul>
      <li>ručně balené</li>
      <li>vlastní kombinace</li>
      <li>česká výroba</li>
      <li>jedinečný dárek</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CombiProductGraphics',
  props: {
    image: {
      type: String,
      required: true,
    },
  },
};
</script>