<template>
  <div class="combi-products-preview">
    <template v-for="(shot, index) in shotPhotos">
      <img class="product-preview-plus" src="../../assets/images/combi-products/plus.png" alt="" v-if="index !== 0"
           :key="`plus-${index}`">
      <img class="product-preview-glass" v-if="shot" :src="shot" alt="Fotka vybreného shotu" :key="`shot-${index}`">
      <img class="product-preview-glass" v-else src="../../assets/images/combi-products/unknown-small-shot.png"
           alt="Fotka neznámého shotu" :key="`shot-unknown-${index}`">
    </template>
  </div>
</template>

<script>
export default {
  name: 'CombiProductPreview',
  props: {
    shotPhotos: {
      type: Array,
      required: true,
    },
  },
};
</script>