<template>
	<div v-if="product" class="product-detail combi-product-detail" :key="product.id">
		<div class="product-detail-top fold-bg" :class="`fold-bg-${product.color} smaller-bottom-padding`">
			<div class="container">
				<div class="row">
          <div class="col-lg-6 image-column">
            <CombiProductGraphics :image="product.image" />
            <CombiProductPreview :shotPhotos="Object.keys(shots).map((index) => selectedProduct(index)?.photo)" />
          </div>
          <div class="col-lg-6 details-column">
            <div class="top-description">
              <h1 class="italic font-brown">{{ product.name }}</h1>
              <p>
                Udělejte radost někomu blízkému a darujte dárkový set s pěti libovolnými ginger shoty.
              </p>
            </div>

            <div class="combi-product-configurator">
              <div v-for="x in Object.values(shots).length" :key="x" class="product-select">
                <label :for="`product-select-${x}`">
                  Produkt č. {{x}}
                </label>
                <div class="pretty-select inverted">
                  <select v-model="shots[x]" :id="`product-select-${x}`">
                    <option disabled :value="null">Vyberte možnost</option>
                    <option v-for="{ value, label } in productOptions" :value="value" :key="label">{{label}}</option>
                  </select>
                </div>
              </div>

              <div class="combi-date-picker">
                <h3>
                  Termín odeslání
                  <HelperTooltip color="white" v-if="$api.cart.ship_at === null">
                    <b>TIP:</b> Budete dárkový set dávat např. někomu na narozeniny nebo Vánoce za delší dobu a chcete shoty úplně čerstvé? Není problém! Vyberte si den, kdy balení chcete od nás odeslat. Doručení je ihned na druhý den.
                  </HelperTooltip>
                </h3>
                <div class="combi-date-picker-input-wrapper">
                  <template v-if="$api.cart.ship_at === null">
                    <label class="custom-check smaller">
                      <span class="label">
                        Odeslat co nejdříve
                      </span>
                      <input type="checkbox" name="payment" v-model="shipAsap">
                      <span class="marker"></span>
                    </label>
                    <date-picker v-if="!shipAsap" v-model="shipAtDate" format="DD. MM. YYYY" prefix-class="xmx" :disabled-date="isDateDisabled" placeholder="Vyberte datum"></date-picker>
                  </template>
                  <p v-else>
                    {{$api.cart.shipAtReadable}}
                    <HelperTooltip color="white">
                      Termín odeslání byl převzatý z dárkového setu, který jste do košíku přidali dříve. Pokud si přejete produkt odeslat v jiný termín, vytvořte další objednávku.
                    </HelperTooltip>
                  </p>
                </div>
              </div>
              <Price :product="product" color="black" :allow-buy="this.canBeAddedToCart" :disabled-buy-callback="disabledBuyCallback" default-size="smaller" @changeSize="() => {}" :pick-size="false" :hide-subscription="true">
                <template v-slot:tooltip>
                  <div v-for="{ count, value, label, price } in selectedProductsGrouped" :key="value">
                    {{count}}x {{label}} = {{$price(price * count)}}
                  </div>
                  <div v-if="allOptionsSelected">
                    Dárkové balení = {{product.price}}
                  </div>
                </template>
              </Price>
            </div>
          </div>
				</div>
			</div>
		</div>
    <div class="other-products-wrapper fold-bg-after fold-bg-after-white">
      <div class="container">
        <h2 class="outline">Další produkty</h2>
        <OtherProducts :exclude="slug" :syrup="false" :syrup-exclude="false" :preferential-sorting="true"/>
      </div>
    </div>
	</div>
</template>

<script>
	import OtherProducts from "../components/OtherProducts";
	import {EventBus} from "../../event-bus";
  import FBPixel from "../../FBPixel";
  import Price from '@/views/pages/Product/Price.vue';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/locale/cs';
  import HelperTooltip from '@/views/components/HelperTooltip.vue';
  import CombiProductPreview from '@/views/components/CombiProductPreview.vue';
  import CombiProductGraphics from '@/views/components/CombiProductGraphics.vue';

  export const getProductOptions = (allProducts) =>
      Object.values(allProducts)
          .filter(({ is_syrup, sold_out }) => is_syrup === false && sold_out === false)
          .map((product) => ({
            label: product.name,
            value: product.id,
            price: product.price_smaller_raw ?? 0,
            photo: product.photo_smaller,
          }))
          .filter(({ price }) => price !== 0);

	export default {
		name: "CombiProduct",
		metaInfo() {
			return {
				title: this.product ? this.product.name : null,
				meta: [{
					name: "description",
					content: `Detail produktu ${this.product ? this.product.name : ''}`,
				}]
			};
		},
		components: {
      CombiProductGraphics,
      CombiProductPreview,
      HelperTooltip,
      Price,
      OtherProducts, DatePicker,},
		data() {
			return {
        shipAsap: false,
        shipAtDate: null,
        minDate: null,
        maxDate: null,
				slug: this.$route.params.slug,
        shots: {
          1: null,
          2: null,
          3: null,
          4: null,
          5: null,
        }
			};
		},
		watch: {
			$route(newRoute) {
				this.slug = newRoute.params.slug;
			},
			product(newProduct) {
				this.created(newProduct);
			}
		},
		computed: {
			product() {
				let products = this.$api.combiProducts;

				// Products not loaded yet
				if (!Object.keys(products).length)
					return null;

        const combiProduct = products[0];

        const shotsPrice = this.selectedProducts.reduce((acc, { price }) => acc + price, 0);

				return {
          ...combiProduct,
          color: 'white',
          price_smaller_raw: shotsPrice + (this.allOptionsSelected ? combiProduct.price_raw : 0), // add box size only if all shots have been selected
          type: 'combi',
          additionalOptions: {
            products: this.selectedProducts.map(({ value }) => ({
              id: value,
              size: 'smaller',
            })),
            shipAt: this.$api.cart.ship_at ?? this.shipAtFormatted,
          },
        };
			},
      shipAtFormatted() {
        if (this.shipAsap) {
          return "asap";
        }

        if (!this.shipAtDate) {
          return null;
        }

        // Convert to YYYY-MM-DD
        return new Date(this.shipAtDate.getTime() - (this.shipAtDate.getTimezoneOffset()*60*1000))
            .toISOString()
            .split('T')[0];
      },
      selectedProducts() {
        return Object.keys(this.shots)
            .map((index) => this.selectedProduct(index))
            .filter((product) => product !== undefined);
      },
      selectedProductsGrouped() {
        return this.selectedProducts
            .reduce((acc, product) => {
              const sameProduct = acc.find(({ value }) => value === product.value) ?? { ...product, count: 0 };
              return [
                ...acc.filter(({ value }) => value !== product.value),
                {
                  ...sameProduct,
                  count: sameProduct.count + 1,
                },
              ];
            }, [])
            .sort(({ count: count1 }, { count: count2 }) => count2 - count1);
      },
      productOptions() {
        return getProductOptions(this.$api.products);
      },
      canBeAddedToCart() {
        return !!(this.allOptionsSelected && (this.$api.cart.ship_at || this.shipAsap || this.shipAtDate !== null));
      },
      allOptionsSelected() {
        return Object.values(this.shots).find((x) => x === null) === undefined;
      },
		},
		methods: {
			created(product) {
				EventBus.$emit("changeTheme", product.color);
        FBPixel.viewProduct(product);
			},
      selectedProduct(index) {
        return this.productOptions.find(({value}) => value === this.shots[index]);
      },
      disabledBuyCallback() {
        EventBus.$emit(
            'flash',
            !this.allOptionsSelected ? 'Vyberte prosím všechny produkty' : 'Vyberte prosím termín odeslání',
            'error',
        );
      },
      isDateDisabled(d) {
        const weekDay = d.getDay();
        const day = d.getDate();
        const month = d.getMonth()+1;
        const year = d.getFullYear();

        return d <= this.minDate || // in the past
            d >= this.maxDate ||    // long in the future
            weekDay === 0 ||     // Sunday
            weekDay >= 5 ||      // Friday or Saturday
            this.$api.closureDays.some(([day2, month2, year2]) => day === day2 && month === month2 && (year2 === undefined || year2 === year))  // forbidden by backend configuration
        ;
      },
		},
		created() {
			if (this.product)
				this.created(this.product);

      this.minDate = new Date();  // today
      this.maxDate = new Date();  // one year in the future
      this.maxDate.setFullYear(this.maxDate.getFullYear() + 1);
		}
	}
</script>